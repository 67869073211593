<template>
  <div class="appointments">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <DataTable
      class="p-datatable-sm"
      :rowClass="rowClass"
      :rowHover="true"
      :value="appointments"
      :loading="isLoading"
      editMode="cell"
      :lazy="true"
      filterDisplay="row"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      :totalRecords="getAppointmentCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      :expandedRows.sync="expandedRows"
      @row-expand="onRowExpand"
      @row-collapse="onRowCollapse"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <div class="mt-2">
              {{ getAppointmentCount }}
              {{ $tc('appointment_pluralized', getAppointmentCount) }}
              <span
                v-if="
                  tableState.filters.installationStartAt &&
                    tableState.filters.installationStartAt.value
                "
              >
                im Zeitraum
                {{
                  tableState.filters.installationStartAt.value
                    ? $date(tableState.filters.installationStartAt.value)
                    : 'N/A'
                }}
                bis
                {{
                  tableState.filters.installationEndAt.value
                    ? $date(tableState.filters.installationEndAt.value)
                    : 'N/A'
                }}
              </span>
            </div>
          </div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
            <div class="button-right">
              <button class="btn btn-success mb-2 right" @click="onCreateAppointment">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('new') }}
              </button>
            </div>
            <div class="button-right">
              <Button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="expandAll">
                Alle öffnen
              </Button>
              <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="collapseAll">
                Alle schliessen
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column :expander="true" :headerStyle="'width: 30px'" />

      <Column
        field="appointmentType"
        :header="$t('appointmentType')"
        :styles="{ width: '7%' }"
        :showFilterMenu="false"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getAppointmentTypeColor(slotProps.data.appointmentType)">
            {{ $t(slotProps.data.appointmentType) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('AppointmentType')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.appointmentTypes"
            :options="appointmentTypes"
            @input="onAppointmentTypeFilter"
            optionLabel="label"
            :placeholder="$t('appointmentType')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column
        field="appointmentState"
        :header="$t('appointmentState')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getAppointmentStateColor(slotProps.data.appointmentState)">
            {{ $t(slotProps.data.appointmentState) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('AppointmentState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getAppointmentStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getAppointmentStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.appointmentStates"
            :options="appointmentStates"
            @input="onAppointmentStateFilter"
            optionLabel="label"
            :placeholder="$t('appointmentState')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column
        field="appointmentApprovedState"
        :header="$t('appointmentApprovedState_short')"
        :styles="{ width: '7%' }"
        :showFilterMenu="false"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getYesNoStateColor(slotProps.data.appointmentApprovedState)">
            {{ $t(slotProps.data.appointmentApprovedState) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown v-model="data[field]" :options="yesNo()" :placeholder="$t('select')">
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <Dropdown
            :value="tableState.customFilters.appointmentApprovedState.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="onAppointmentApprovedStateFilter"
            :style="{ 'max-width': '100px' }"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="installationStartAt"
        :header="$t('start')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          <strong> {{ $dayjs(slotProps.data[slotProps.column.field]).format('dddd') }}</strong
          ><br />
          {{ $datetime(slotProps.data[slotProps.column.field]) }}
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="installationEndAt"
        :header="$t('end')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          {{ $datetime(slotProps.data[slotProps.column.field]) }}
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder="<="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="project.number"
        header="#"
        :styles="{ width: '7%' }"
        :headerStyle="{ width: '60px' }"
        headerClass="center"
        className="center"
        filterField="projectNumber"
        sortable
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.project && slotProps.data.project.number"
            target="_blank"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.project.number },
            }"
            >{{ slotProps.data.project.number.toString().padStart(4, '0') }}
            <i class="fa fa-sm fa-external-link"></i>
          </router-link>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="project" :header="$t('customer')" sortable>
        <template #body="slotProps">
          <multiselect
            class="multiselect-project"
            :placeholder="$t('filter_by_lastname')"
            v-model="slotProps.data.project"
            :options="filteredProjects"
            :allow-empty="false"
            track-by="number"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :internal-search="false"
            :show-labels="true"
            v-on:search-change="onProjectSearch"
            v-on:select="onSelectProject(slotProps)"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
              <div>
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.streetNumberSuffix }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </div>
            </template>
            <template slot="option" slot-scope="{ option }">
              <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
              <div>
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.streetNumberSuffix }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </div>
            </template>
          </multiselect>
        </template>

        <template #filter>
          <InputText
            :value="tableState.customFilters.projectCustomerSearch.value"
            @input="onProjectFilter"
          />
        </template>
      </Column>

      <Column
        field="project.customer.zip"
        :header="$t('zip')"
        :styles="{ width: '7%' }"
        headerClass="center"
        className="center"
        filterField="projectCustomerZip"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="project.solarPlant.solarPanelCount"
        :header="$t('solarPanelCount_short')"
        headerClass="center"
        className="center"
        :styles="{ 'min-width': '120px' }"
        filterField="projectSolarPanelCount"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            placeholder=">="
          />
        </template>
        >
      </Column>

      <Column
        field="project.employer.name"
        :header="$t('employer')"
        headerClass="center"
        className="center"
        filterField="projectEmployerSearch"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="employees" :header="$t('employee')" v-if="isAdmin" sortable>
        <template #body="slotProps">
          <multiselect
            class="multiselect-employees"
            v-model="slotProps.data.employees"
            :options="filteredEmployees"
            track-by="number"
            v-on:search-change="onFilterEmployees"
            v-on:select="onSelectEmployee(slotProps)"
            v-on:remove="onSelectEmployee(slotProps)"
            :filter="true"
            :internal-search="false"
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :show-labels="false"
            :allow-empty="true"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :placeholder="'Mitarbeiter ' + $t('select') + '/suchen'"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.firstname }} {{ option.lastname }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option.firstname }} {{ option.lastname }}
            </template>
            <template slot="tag" slot-scope="{ option }">
              <div>{{ option.firstname }} {{ option.lastname }}</div>
            </template>
            <template slot="noResult">
              <div>{{ $t('no_results') }}</div>
            </template>
          </multiselect>
        </template>

        <template #filter>
          <InputText
            :value="tableState.customFilters.employeeSearch.value"
            @input="onEmployeeSearch"
          />
        </template>
      </Column>

      <Column field="itemStorages" :header="$t('vehicles')" v-if="isAdmin" sortable>
        <template #body="slotProps">
          <multiselect
            class="multiselect-itemStorages"
            v-model="slotProps.data.itemStorages"
            :options="filteredItemStorages"
            track-by="number"
            v-on:search-change="onFilterItemStorages"
            v-on:select="onSelectItemStorage(slotProps)"
            v-on:remove="onSelectItemStorage(slotProps)"
            :filter="true"
            :internal-search="false"
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :show-labels="false"
            :allow-empty="true"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :placeholder="'Fahrzeug ' + $t('select') + '/suchen'"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.name }}
            </template>
            <template slot="option" slot-scope="{ option }"> {{ option.name }} </template>
            <template slot="tag" slot-scope="{ option }">
              <div>{{ option.name }}</div>
            </template>
            <template slot="noResult">
              <div>{{ $t('no_results') }}</div>
            </template>
          </multiselect>
        </template>

        <template #filter>
          <InputText
            :value="tableState.customFilters.itemStorageSearch.value"
            @input="onItemStorageSearch"
          />
        </template>
      </Column>

      <Column field="description" :header="$t('notes')" sortable :styles="{ 'min-width': '200px' }">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column :styles="{ 'min-width': '110px' }">
        <template #body="slotProps">
          <ConfirmButton
            colorVariant="white"
            :callbackId="slotProps.data"
            :confirmCallback="onDuplicateAppointment"
            :preventHide="monthChangeClicked"
            :hideCallback="resetPreventHide"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-copy mr-2"></i>
            </template>
            <template v-slot:header>
              {{ $t('duplicate_appointment') }}: Datum und Uhrzeit wählen
            </template>
            <template v-slot:confirmButtonText>
              {{ $t('duplicate_appointment') }}
            </template>
            <template v-slot:content>
              <Calendar
                :inline="true"
                v-model="copyDate"
                :showTime="true"
                :stepMinute="15"
                :styles="{ width: '100%' }"
                @month-change="onCalendarMonthChange"
                @year-change="onCalendarMonthChange"
              >
              </Calendar>
            </template>
          </ConfirmButton>

          <ConfirmButton
            colorVariant="white"
            :disabled="emailButtonDisabled(slotProps.data)"
            :callbackId="slotProps.data"
            :confirmCallback="onSendAppointmentConfirmationMail"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-envelope mr-2"></i>
            </template>
            <template v-slot:header> {{ $t('confirm_appointment') }}? </template>
            <template v-slot:confirmButtonText>
              {{ $t('confirm_appointment') }}
            </template>
            <template v-slot:content>
              E-Mail-Bestätigung wird versendet an:
              <strong v-if="slotProps.data.project && slotProps.data.project.customer.email">
                {{ slotProps.data.project.customer.email }}</strong
              >
            </template>
          </ConfirmButton>
          <ConfirmButton
            colorVariant="white"
            :callbackId="slotProps.data.number"
            :confirmCallback="onDeleteAppointment"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-times mr-2"></i>
            </template>
            <template v-slot:header> {{ $t('delete_appointment') }}? </template>
            <template v-slot:confirmButtonText>
              {{ $t('delete_appointment') }}
            </template>
          </ConfirmButton>
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="expansion grid" :class="rowClass">
          <div
            v-if="
              slotProps.data.backlog &&
                (slotProps.data.appointmentState === 'BACKLOG' ||
                  slotProps.data.appointmentState === 'ALTERNATIVE_DATE')
            "
          >
            <div class="flex-row">
              <div>
                <h6>
                  <b-badge :variant="getAppointmentStateColor(slotProps.data.appointmentState)">
                    {{ $t(slotProps.data.appointmentState) }}
                  </b-badge>
                </h6>
              </div>
              <div></div>
            </div>
            <div class="flex-row">
              <div>
                <div>{{ $t('backlogReason') }}</div>
              </div>
              <div>
                <div>{{ $t(slotProps.data.backlog.backlogReason) }}</div>
              </div>
            </div>
            <div class="flex-row">
              <div>
                <div>{{ $t('description') }}</div>
              </div>
              <div>
                <div>{{ slotProps.data.backlog.description }}</div>
              </div>
            </div>
            <div class="flex-row">
              <div>
                <div>{{ $t('remainingWork') }}</div>
              </div>
              <div>
                <div>{{ slotProps.data.backlog.remainingWork }}</div>
              </div>
            </div>
            <div class="flex-row">
              <div>
                <div>{{ $t('remainingTimeExpenditure') }}</div>
              </div>
              <div>
                <div>{{ $t(slotProps.data.backlog.remainingTimeExpenditure) }}</div>
              </div>
            </div>
            <div class="flex-row">
              <div>
                <div>{{ $t('createdAt') }}</div>
              </div>
              <div>
                <div>{{ $datetime(slotProps.data.backlog.createdAt) }}</div>
              </div>
            </div>
            <!-- <div class="flex-row">
              <div>
                <div>{{ $t('updatedAt') }}</div>
              </div>
              <div>
                <div>{{ $datetime(slotProps.data.backlog.updatedAt) }}</div>
              </div>
            </div> -->
          </div>
          <div>
            <div class="flex-row">
              <div>
                <h6>{{ $t('notes') }}</h6>
              </div>
              <div></div>
            </div>

            <div class="flex-row">
              <div>
                <div v-if="slotProps.data.description">{{ slotProps.data.description }}</div>
                <div v-else>N/A</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="mt-2">
          {{ getAppointmentCount }}
          {{ $tc('appointment_pluralized', getAppointmentCount) }}
          <span
            v-if="
              tableState.filters.installationStartAt && tableState.filters.installationStartAt.value
            "
          >
            im Zeitraum
            {{
              tableState.filters.installationStartAt.value
                ? $date(tableState.filters.installationStartAt.value)
                : 'N/A'
            }}
            bis
            {{
              tableState.filters.installationEndAt.value
                ? $date(tableState.filters.installationEndAt.value)
                : 'N/A'
            }}
          </span>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="Begründung erforderlich"
      :visible.sync="displayBacklogConfirmation"
      :contentStyle="{ overflow: 'visible' }"
      :modal="true"
    >
      <div v-if="tmpBacklogAppointment">
        <div>#{{ tmpBacklogAppointment.number }}</div>
        <div class="mt-2">
          <b-badge :variant="getAppointmentTypeColor(tmpBacklogAppointment.appointmentType)">
            {{ $t(tmpBacklogAppointment.appointmentType) }}
          </b-badge>
          am <strong> {{ $datetime(tmpBacklogAppointment.installationStartAt) }}</strong>
        </div>
        <div v-if="tmpBacklogAppointment.project" class="mt-2">
          <div>
            {{ tmpBacklogAppointment.project.customer.lastname }},
            {{ tmpBacklogAppointment.project.customer.firstname }},
          </div>
          <div>
            {{ tmpBacklogAppointment.project.customer.street }}
            {{ tmpBacklogAppointment.project.customer.streetNumber }},
            {{ tmpBacklogAppointment.project.customer.zip }}
            {{ tmpBacklogAppointment.project.customer.city }}
          </div>
        </div>
        <div class="mt-4">
          Neuer Terminstatus:
          <b-badge :variant="getAppointmentStateColor(tmpBacklogAppointment.appointmentState)">
            {{ $t(tmpBacklogAppointment.appointmentState) }}
          </b-badge>
        </div>
      </div>
      <div v-if="tmpBacklogAppointment && tmpBacklogAppointment.backlog" class="mt-4">
        <div class="flex-row">
          <div>{{ $t('backlogReason') }}</div>
          <div>
            <Dropdown
              v-model="tmpBacklogAppointment.backlog.backlogReason"
              :options="getEnumValues('BacklogReason')"
              :placeholder="$t('select')"
            >
              <template #option="slotProps">
                <b-badge>
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
              <template #value="slotProps">
                <b-badge>
                  {{ $t(slotProps.value) }}
                </b-badge>
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="flex-row">
          <div>{{ $t('description') }}</div>
          <div>
            <Textarea v-model="tmpBacklogAppointment.backlog.description" rows="3" cols="40" />
          </div>
        </div>
        <div class="flex-row">
          <div>{{ $t('remainingWork') }}</div>
          <div>
            <Textarea v-model="tmpBacklogAppointment.backlog.remainingWork" rows="3" cols="40" />
          </div>
        </div>
        <div class="flex-row">
          <div>{{ $t('remainingTimeExpenditure') }}</div>
          <div>
            <Dropdown
              v-model="tmpBacklogAppointment.backlog.remainingTimeExpenditure"
              :options="getEnumValues('RemainingTimeExpenditure')"
              :placeholder="$t('select')"
            >
              <template #option="slotProps">
                <b-badge>
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
              <template #value="slotProps">
                <b-badge>
                  {{ $t(slotProps.value) }}
                </b-badge>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>

      <template #footer>
        <Button class="btn btn-narrow btn-default ml-2 mb-2" @click="onCancelBacklogReason">
          {{ $t('cancel') }}
        </Button>
        <Button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="onSaveBacklogReason">
          {{ $t('save') }}
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import ConfirmButton from '@/components/ConfirmButton';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Multiselect from 'vue-multiselect';
import MultiSelect from 'primevue/multiselect';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';
import { yesNo } from '@/helpers/enums';
import { FilterMatchMode } from 'primevue/api';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';

export default {
  name: 'Appointments',
  components: {
    Breadcrumbs,
    DataTable,
    Calendar,
    Column,
    InputText,
    Dropdown,
    Multiselect,
    ConfirmButton,
    MultiSelect,
    Dialog,
    Textarea,
  },
  data() {
    return {
      displayBacklogConfirmation: false,
      tmpBacklogAppointment: null,
      oldAppointmentState: null,
      expandedRows: [],
      monthChangeClicked: false,
      selectingEndDate: false,
      copyDate: this.$dayjs()
        .startOf('day')
        .add(8, 'hour')
        .toDate(),
      calendarWeek: [],
      filteredEmployees: [],
      filteredItemStorages: [],
      loading: false,
      multiSortMeta: [{ field: 'installationStartAt', order: 1 }],

      /** filters for project multiselect */
      filteredProjects: [],
      projectsFilter: {
        pagination: {
          page: 0,
          rowsPerPage: 20,
        },
        sortField: 'number',
        sortOrder: -1,
        filterName: 'appointments-project-filters',
        filters: {
          number: { value: null, matchMode: FilterMatchMode.EQUALS },
          customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
          customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      },

      /** persistent table filters  */
      defaultFilters: {
        projectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        projectCustomerZip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectEmployerSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectSolarPanelCount: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        installationStartAt: {
          value: this.$dayjs()
            .startOf('week')
            .toDate(),
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        installationEndAt: {
          value: this.$dayjs()
            .startOf('week')
            .add(6, 'days')
            .endOf('day')
            .toDate(),
          matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        },
      },
      defaultCustomFilters: {
        appointmentStates: [],
        appointmentTypes: [],
        appointmentApprovedState: { value: null, matchMode: FilterMatchMode.EQUALS },
        employeeSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemStorageSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectCustomerSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableState: {
        filterName: 'appointments-table-filters',
        customFilterName: 'appointments-custom-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'installationStartAt',
        sortOrder: -1,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getAppointments',
      'getEnumValues',
      'getProjects',
      'getEmployees',
      'getAppointmentCount',
      'getItemStorages',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('appointment_planning') }];
    },
    appointmentStates() {
      const appointmentStates = this.getEnumValues('AppointmentState').map((appointmentState) => {
        return { value: appointmentState, label: this.$t(appointmentState) };
      });
      return appointmentStates;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    appointments() {
      return this.getAppointments;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchAppointmentPages',
      'createAppointment',
      'duplicateAppointment',
      'updateAppointment',
      'initAppointment',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployees',
      'deleteAppointment',
      'sendAppointmentMail',
      'fetchItemStoragesPaginated',
    ]),

    onRowExpand(event) {},
    onRowCollapse(event) {},
    expandAll() {
      this.expandedRows = this.appointments.filter((d) => d.number);
    },
    collapseAll() {
      this.expandedRows = null;
    },

    onCalendarMonthChange(e, data) {
      this.monthChangeClicked = true;
    },

    resetPreventHide() {
      this.$nextTick(() => {
        this.monthChangeClicked = false;
      });
    },

    /**
     * search in dropdown for a project to assign to the appointment
     */
    async onProjectSearch(rawQuery) {
      const query = rawQuery.toLowerCase();
      this.projectsFilter.filters.customerLastname.value = query;
      await this.loadProjectData();
    },

    /**
     * only enable email button if customer has the email field filled
     */
    emailButtonDisabled(appointment) {
      return !(appointment.project && appointment.project.customer.email) ? true : false;
    },

    /**
     *
     */
    async onFilterEmployees(query) {
      // console.log(query);
      this.filteredEmployees = [];
      this.getEmployees.map((employee) => {
        if (
          (employee.firstname && employee.firstname.toLowerCase().indexOf(query) >= 0) ||
          (employee.lastname && employee.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          this.filteredEmployees.push(employee);
        }
      });
    },

    async onSelectProject(row) {
      // console.log('onSelectProject', row);
      const { data } = row;
      this.save(data);
    },

    async onSelectEmployee(row) {
      // console.log('onSelectEmployee', row);
      const { data } = row;
      await this.save(data);
    },

    async onFilterItemStorages(query) {
      this.filteredItemStorages = [];
      this.getItemStorages.map((itemStorage) => {
        if (itemStorage.name && itemStorage.name.toLowerCase().indexOf(query) >= 0) {
          // console.log(itemStorage);
          this.filteredItemStorages.push(itemStorage);
        }
      });
    },

    async onSelectItemStorage(row) {
      // console.log('onSelectItemStorage', row);
      const { data } = row;
      this.save(data);
    },

    onAppointmentStateChange(event) {
      // console.log('onAppointmentStateChange():', event);
      event.originalEvent.preventDefault();
      this.displayBacklogConfirmation = true;
    },

    onCancelBacklogReason() {
      this.tmpBacklogAppointment.appointmentState = this.oldAppointmentState;
      this.displayBacklogConfirmation = false;
    },
    onSaveBacklogReason() {
      this.save(this.tmpBacklogAppointment);
      this.displayBacklogConfirmation = false;
    },

    /**
     * send appointment data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, value, field } = event;
      console.log('onCellEditComplete():', event);

      /** Popup on backlog and alternative date */
      if (
        (field === 'appointmentState' && newValue === 'BACKLOG') ||
        newValue === 'ALTERNATIVE_DATE'
      ) {
        // console.log(data, newValue, field);

        /** Init Backlog */
        if (!data.backlog) {
          data.backlog = {
            backlogReason: 'WEATHER',
            description: '',
            remainingWork: '',
            remainingTimeExpenditure: 'DAY',
          };
        }

        this.oldAppointmentState = value;
        data[field] = newValue;
        this.tmpBacklogAppointment = data;
        this.displayBacklogConfirmation = true;
        return;
      }

      if (field === 'installationStartAt') {
        // update date grouping (day of week)
        data.weekday = this.$dayjs(newValue)
          .startOf('day')
          .format('dddd DD.MM.YYYY');

        // set installationEndAt to installationStartAt + 4 hour
        data.installationEndAt = this.$dayjs(newValue)
          .add(8, 'hour')
          .toDate();
      }

      data[field] = newValue;
      this.save(data);
    },

    /**
     * Save appointment
     * @param {*} appointment
     */
    async save(appointment) {
      if (appointment.number === '_new') {
        await this.createAppointment(appointment);
      } else {
        await this.updateAppointment(appointment);
      }
    },

    onCreateAppointment() {
      this.initAppointment();
    },

    /**
     * Copy the appointment data to a new appointment
     * Set the start date selected in the ConfirmButton Popup
     * @param {*} appointment
     */
    async onDuplicateAppointment(appointment) {
      const newAppointment = await this.duplicateAppointment({
        appointment: appointment,
        installationStartAt: this.copyDate,
      });
      this.save(newAppointment);
      appointment.appointmentState = 'ALTERNATIVE_DATE';
      this.save(appointment);
    },

    onDeleteAppointment(appointmentNumber) {
      // console.log('onDeleteAppointment():', appointmentNumber);
      this.deleteAppointment(appointmentNumber);
    },

    /**
     * send confirmation mail
     */
    async onSendAppointmentConfirmationMail(appointment) {
      await this.sendAppointmentMail({
        appointmentNumber: appointment.number,
      });
    },

    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      const weekday = this.$dayjs(data.installationStartAt)
        .locale('en')
        .format('dddd')
        .toLowerCase();

      return data.number === '_new' ? 'new' : weekday;
    },

    async loadProjectData() {
      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      }
      this.filteredProjects = this.getProjects;
    },

    /**
     * Load remote table data
     */
    async loadAppointmentData() {
      await this.fetchAppointmentPages({
        page: this.pageOffset,
        pageSize: this.tableState.pagination.rowsPerPage,
        sortField: this.tableState.sortField,
        sortOrder: this.tableState.sortOrder,
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
      });
    },

    /**
     * Load results from server/cache on filter
     */
    onFilter(event) {
      let filters = { ...this.tableState.filters };

      if (filters.installationEndAt && filters.installationEndAt.value) {
        filters.installationEndAt.value = this.$dayjs(filters.installationEndAt.value)
          .endOf('day')
          .toDate();
      }

      this.loadAppointmentData();
    },
    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      // console.log('onPage', event);
      this.loadAppointmentData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentApprovedStateFilter(value) {
      if (this.tableState.customFilters.appointmentApprovedState) {
        this.tableState.customFilters.appointmentApprovedState.value = value;
      }

      await this.loadAppointmentData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      // console.log('onStateRestore():', this.tableState);

      const customFiltersFromStorage = JSON.parse(
        localStorage.getItem(this.tableState.customFilterName)
      );
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;

      this.setupFilters();
    },

    /**
     *
     */
    async onAppointmentStateFilter(value) {
      this.tableState.customFilters.appointmentStates = this.appointmentStates.filter(
        (appointmentState) => value.includes(appointmentState)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentTypeFilter(value) {
      this.tableState.customFilters.appointmentTypes = this.appointmentTypes.filter(
        (appointmentType) => value.includes(appointmentType)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    /**
     * Search for assigned employees and save state to local storage
     */
    async onEmployeeSearch(value) {
      if (this.tableState.customFilters.employeeSearch) {
        this.tableState.customFilters.employeeSearch.value = value;
      }

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    /**
     * Search for assigned itemStorages and save state to local storage
     */
    async onItemStorageSearch(value) {
      if (this.tableState.customFilters.itemStorageSearch) {
        this.tableState.customFilters.itemStorageSearch.value = value;
      }

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    /**
     * Search and filter for fields in projects that are assigned to the appointment
     */
    async onProjectFilter(value) {
      if (this.tableState.customFilters.projectCustomerSearch) {
        this.tableState.customFilters.projectCustomerSearch.value = value;
      }

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    async onRemoveFilters(event) {
      this.tableState.filters = this.defaultFilters;
      this.tableState.customFilters = this.defaultCustomFilters;

      this.setupFilters();
      localStorage.removeItem(this.tableState.filterName);
      localStorage.removeItem(this.tableState.customfilterName);
      await this.loadAppointmentData();
    },

    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;

      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 20;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },

    getAppointmentTypeColor,
    getAppointmentStateColor,
    getYesNoStateColor,
    yesNo,
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    await this.fetchEnumValues('AppointmentType');
    await this.fetchEnumValues('AppointmentState');
    await this.fetchEnumValues('BacklogReason');
    await this.fetchEnumValues('RemainingTimeExpenditure');
    await this.fetchEmployees({
      page: 0,
      pageSize: 1000,
      sortField: 'lastname',
      sortOrder: 1,
      filters: { activeState: { value: 'ACTIVE' } },
    });
    await this.fetchItemStoragesPaginated({
      pagination: { skip: 0, pageSize: 100 },
      sorting: { sortField: 'number', sortOrder: 1 },
      filters: { itemStorageType: { value: 'VEHICLE', matchMode: FilterMatchMode.CONTAINS } },
    });
    this.filteredEmployees = this.getEmployees;
    this.filteredItemStorages = this.getItemStorages;
    await this.loadAppointmentData();
    await this.loadProjectData();
  },
};
</script>

<style scoped lang="scss">
::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

::v-deep .p-datatable .p-datatable-tbody > tr.monday {
  background: rgba(233, 207, 179, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.monday:hover {
  background: rgba(233, 207, 179, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.tuesday {
  background: rgba(217, 188, 130, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.tuesday:hover {
  background: rgba(217, 188, 130, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.wednesday {
  background: rgba(201, 176, 166, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.wednesday:hover {
  background: rgba(201, 176, 166, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.thursday {
  background: rgba(217, 227, 224, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.thursday:hover {
  background: rgba(217, 227, 224, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.friday {
  background: rgba(210, 208, 157, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.friday:hover {
  background: rgba(210, 208, 157, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.saturday {
  background: rgba(205, 215, 193, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.saturday:hover {
  background: rgba(205, 215, 193, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.sunday {
  background: rgba(228, 233, 222, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.sunday:hover {
  background: rgba(228, 233, 222, 1);
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-itemStorages,
.multiselect-employees {
  min-width: 180px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-employees .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

.btn-xs {
  border-radius: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
.expansion {
  text-align: left;
  margin-left: 1.5em;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1em;
}
.grid > div {
  padding: 1em 1em;
  border: 2px;
  border: 1px solid $gray-300;
  background: white;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  width: 100%;
  gap: 0.3em;
  margin-bottom: 0.2em;
}

.flex-row > div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-word;
}

::v-deep .p-dialog {
  min-width: 500px;
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 75vh;
}
</style>
